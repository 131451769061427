<template>
  <app-layout class="bg-background">
    <div class="container mx-auto">
      <app-modal-user-detail
        :show="showModalDetail"
        @close="showModalDetail = false"
        :selectUser="selectUser"
        @update:selectUser="selectUser = $event"
        @refresh="refresh"
        @resend-password="resendResetPassword"
      />
      <app-table
        :apiResponse="apiPaginationResponse.data"
        :loading="apiPaginationLoading"
        @params-changed="params_changed"
        :showEmptyButton="false"
        :filters="[
          {
            key: 'filter[id]',
            type: 'text',
            placeholder: $t('user.detail.id'),
            value: '',
          },
          {
            key: 'filter[name]',
            type: 'text',
            placeholder: $t('user.detail.name'),
            value: '',
          },
          {
            key: 'filter[email]',
            type: 'text',
            placeholder: $t('user.detail.email'),
            value: '',
          },
          {
            key: 'filter[country_id]',
            type: 'select',
            placeholder: $t('user.detail.country'),
            items: countries,
          },
          {
            key: 'filter[created_at_between]',
            type: 'date',
            value: '',
          },
        ]"
        @on-item-click="onItemClick"
      >
        <template v-slot:header>
          <th class="font-bold">{{ $t('user.detail.id') }}</th>
          <th class="font-bold">{{ $t('user.detail.name') }}</th>
          <th class="font-bold">{{ $t('user.detail.email') }}</th>
          <th class="font-bold">{{ $t('user.detail.country') }}</th>
          <th class="font-bold">{{ $t('user.detail.register_at') }}</th>
          <th class="font-bold">{{ $t('user.detail.action') }}</th>
        </template>
        <template v-slot:body="slotData">
          <td class="flex place-items-center space-x-2 justify-between">
            <p class="font-medium">
              {{ slotData.model.id }}
            </p>
          </td>
          <td>
            {{ slotData.model.name }}
          </td>
          <td>
            {{ slotData.model.email }}
          </td>
          <td>
            {{ slotData.model.country.name }}
          </td>
          <td>
            {{
              $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a")
            }}
          </td>
          <td>
            <div class="flex flex-row space-x-1">
              <button @click.stop="resendResetPassword(slotData.model.email)">
                <div
                  class="
                    px-2
                    py-1
                    border
                    rounded-md
                    flex flex-row
                    justify-center
                    bg-background
                    place-items-center
                    hover:bg-gray-100
                    space-x-2
                  "
                >
                  <app-icon-loading
                    v-if="loadingAuth"
                    iconColor="bg-primary"
                    customClass="w-5 h-5"
                  />
                  <app-icon
                    v-else
                    name="MailIcon"
                    class="h-5 w-5 text-gray-400"
                  />

                  <p class="text-xs">{{ $t("general.reset_password") }}</p>
                </div>
              </button>
            </div>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
      current_tab: null,
      showModalDetail: false,
      filters: "",
      selectUser: null,
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["userStore/apiPaginationResponse"];
    },
    countries() {
      return this.$store.getters["referenceStore/countries"];
    },
    apiPaginationLoading() {
      return this.$store.getters["userStore/apiPaginationLoading"];
    },
    loadingAuth() {
      return this.$store.getters["authStore/loading"];
    },
  },
  mounted() {
    this.fetchListUser();
    this.$store.dispatch("referenceStore/getCountries", 0);
  },
  methods: {
    refresh() {
      this.fetchListUser();
    },
    fetchListUser() {
      this.$store.dispatch("userStore/retrieveUsers", this.filters);
    },
    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectUser = data;
    },
    params_changed(filters, queryParams) {
      this.filters = filters;
      this.filterFormatDate(filters["filter[created_at_between]"]);
      this.filters["filter[country_id]"] = filters["filter[country_id]"]?.id;
      this.fetchListUser();
    },
    async resendResetPassword(email) {
      const resetPasswordResult = await this.$store.dispatch(
        "authStore/forgotPassword",
        {
          email: email,
        }
      );

      if (resetPasswordResult != null) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.resend_successfully"),
          },
          5000
        );
      }
    },
    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date) this.filters["filter[created_at_between]"] = start + "," + end;
    },
  },
};
</script>
